import {
    AddBoxOutlined,
    AddShoppingCart, ChildCare,
    ContentCopy,
    DoneAll,
    Favorite,
    FavoriteBorder,
    FilterListSharp,
    KeyboardArrowDown,
    KeyboardArrowUp,
    OpenInNew,
    QuestionMark,
    VisibilityOff,
    VisibilitySharp
} from "@mui/icons-material";
import {
    Box, Breadcrumbs,
    CircularProgress,
    IconButton,
    ListDivider,
    Modal,
    ModalClose,
    ModalDialog, Option, Select,
    Typography,
    Link
} from "@mui/joy";
import React from "react";
import {Link as RouterLink, useHistory, useLocation, useParams} from "react-router-dom";
import usersApi from "../api/usersApi";
import {ItemList} from "../components/ItemList";
import {IItem} from "../models/item";
import {IUser} from "../models/user";
import {AppContext} from "../utilities/AppContext";
import {Backdrop, Menu, MenuItem} from "@mui/material";
import useItemList from "../utilities/useItemList";

interface ISortDict {
    [key: string]: (a: IItem, b: IItem) => number;
}

const sortMethods: ISortDict = {
    "dUp": (a: IItem, b: IItem) => new Date(a.createdOnDate) < new Date(b.createdOnDate) ? 1 : -1,
    "dDown": (a: IItem, b: IItem) => new Date(a.createdOnDate) > new Date(b.createdOnDate) ? 1 : -1,
    "pUp": (a: IItem, b: IItem) => a.price === b.price ? a.priority < b.priority ? 1 : -1 : a.price > b.price ? 1 : -1,
    "pDown": (a: IItem, b: IItem) => a.price === b.price ? a.priority < b.priority ? 1 : -1 : a.price < b.price ? 1 : -1,
    "prUp": (a: IItem, b: IItem) => a.priority === b.priority ? a.price > b.price ? 1 : -1 : a.priority > b.priority ? 1 : -1,
    "prDown": (a: IItem, b: IItem) => a.priority === b.priority ? a.price > b.price ? 1 : -1 : a.priority < b.priority ? 1 : -1
}

export type settingsPref = 'current' | 'purchased' | 'deleted' | 'private' | 'gifted';

interface IPrefs {
    sortBy: string;
    settings: settingsPref;
}

const defaultPrefs: IPrefs = {
    sortBy: "prDown",
    settings: "current"
}

const PREFS = "PREFS";
const getPrefs = (): IPrefs => {
    const stored = sessionStorage.getItem(PREFS);
    if (stored == null) {
        sessionStorage.setItem(PREFS, JSON.stringify(defaultPrefs));
        return defaultPrefs;
    }
    return {...defaultPrefs, ...JSON.parse(stored)};
}

const UserItems = () => {
    const {validators: {isUserId}, family, user: loggedInUser} = React.useContext(AppContext);
    const history = useHistory();
    const {userId} = useParams<{ userId: string }>();
    const location = useLocation<IUser | null>();
    const prefs = getPrefs();
    const [user, setUser] = React.useState<IUser>({
        id: location.state?.id || 0,
        email: location.state?.email || "",
        firstName: location.state?.firstName || "filler",
        lastName: location.state?.lastName || "filler",
        birthday: location.state?.birthday || "",
        itemCount: location.state?.itemCount || 0,
        parent: location.state?.parent || null,
        daysTillBirthday: location.state?.daysTillBirthday || 0,
        children: location.state?.children || []
    })
    const [refreshFlag, setRefreshFlag] = React.useState<number>(0);
    const [itemDict, isItemLoading] = useItemList(userId, refreshFlag);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [openHelp, setOpenHelp] = React.useState<boolean>(false);
    const [isDisabled, setIsDisabled] = React.useState(true);
    React.useEffect(() => setIsDisabled(isLoading || isItemLoading), [isLoading, isItemLoading])

    React.useEffect(() => {
        if (location.state?.id === user.id) {
            setIsLoading(false);
            return;
        }
        if (userId === '0' || isNaN(parseInt(userId))) return;
        const controller = new AbortController();
        usersApi.GetUserById(userId, controller.signal)
            .then(setUser)
            .catch(console.warn)
            .finally(() => setIsLoading(false));
        return () => controller.abort();
    }, [userId, location.state?.id, user.id])

    const triggerRefresh = () => setRefreshFlag(refreshFlag + 1);

    const [sortBy, setSortBy] = React.useState<string>(prefs.sortBy);
    const [settings, setSettings] = React.useState<settingsPref>(prefs.settings);

    React.useEffect(() => {
        sessionStorage.setItem(PREFS, JSON.stringify({sortBy, settings}))
    }, [sortBy, settings])

    const SortMenu = () => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const _open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <>
                <IconButton
                    size='sm'
                    id="sort-button"
                    variant='plain'
                    aria-controls={_open ? 'sort-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={_open ? 'true' : undefined}
                    onClick={handleClick}
                    color="primary">
                    <FilterListSharp/>
                </IconButton>
                <Menu
                    id="sort-menu"
                    anchorEl={anchorEl}
                    open={_open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <MenuItem
                        selected={sortBy === "pDown"}
                        onClick={() => setSortBy("pDown")}> Price (High To Low) </MenuItem>
                    <MenuItem
                        selected={sortBy === "pUp"}
                        onClick={() => setSortBy("pUp")}>Price (Low To High)</MenuItem>
                    <ListDivider/>
                    <MenuItem
                        selected={sortBy === "prDown"}
                        onClick={() => setSortBy("prDown")}>Priority (High To Low)</MenuItem>
                    <MenuItem
                        selected={sortBy === "prUp"}
                        onClick={() => setSortBy("prUp")}>Priority (Low To High)</MenuItem>
                    <ListDivider/>
                    <MenuItem
                        selected={sortBy === "dUp"}
                        onClick={() => setSortBy("dUp")}>Added (New To Old)</MenuItem>
                    <MenuItem
                        selected={sortBy === "dDown"}
                        onClick={() => setSortBy("dDown")}>Added (Old To New)</MenuItem>
                </Menu>
            </>
        );
    }

    const BasicMenu = () => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const _open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        const handleEdit = (e: number) => {
            setIsLoading(true);
            history.push(`/${e}`, user.children?.find(u => u.id === e));
        }

        return (
            <>
                <IconButton
                    size='sm'
                    id="basic-button"
                    variant='plain'
                    aria-controls={_open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={_open ? 'true' : undefined}
                    onClick={handleClick}
                    color="primary">
                    {_open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={_open}
                    onClose={handleClose}>
                    {family.filter(c => c.id !== parseInt(userId))
                        .map(c => <MenuItem
                            key={`profile-${c.id}`}
                            defaultValue={c.id}
                            onClick={() => handleEdit(c.id)}>
                            {c.firstName} {c.lastName && c.lastName.length ? c.lastName[0] : ''}.
                        </MenuItem>)}
                </Menu>
            </>
        );
    }

    if (userId && isNaN(parseInt(userId))) {
        history.push('/friends');
        return <></>;
    }
    if (!userId && loggedInUser) {
        history.push(`/${loggedInUser.id}`);
        return <></>;
    }
    return <>
        <Box borderBottom={2} py={1} mb={2} sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Breadcrumbs sx={{padding: 0}}>
                {
                    !isUserId(userId) && !isLoading && <Link component={RouterLink} to='/friends' fontWeight='bold'>
                        Friends
                    </Link>
                }
                <Typography
                    component="h5"
                    display="inline-flex"
                    sx={{
                        display: "flex",
                        color: user.id > 0 ? "inherit" : "transparent"
                    }}>
                    {user.firstName} {user.lastName && user.lastName.length ? user.lastName[0] : ''}.
                </Typography>
            </Breadcrumbs>

            {(!isDisabled && isUserId(userId) && family.length > 1) && <BasicMenu/>}
            {(!isDisabled &&
                    (!isUserId(userId, false) && isUserId(userId)) &&
                    family.length > 1) &&
                <IconButton size='sm' variant='plain'>
                    <ChildCare/>
                </IconButton>
            }

            <IconButton
                size='sm'
                variant='plain'
                color="primary"
                disabled={isDisabled}
                onClick={() => history.push(`/addsuggestion/${userId}`, {
                    isPrivate: settings === 'private',
                    userName: `${user.firstName} ${user.lastName && user.lastName.length ? user.lastName[0] : ''}.`,
                    returnUserId: userId
                })}
                sx={{ml: 'auto'}}>
                <AddBoxOutlined/>
            </IconButton>

            <SortMenu/>
            <IconButton
                size='sm'
                variant='plain'
                color="primary"
                onClick={() => setOpenHelp(true)}>
                <QuestionMark/>
            </IconButton>
        </Box>

        {
            isUserId(userId) && !isDisabled &&
            <Select value={settings} sx={{mb: 2}} onChange={(_, v) => setSettings(v!)}>
                <Option value='current'>Current List ({itemDict.current.length})</Option>
                <Option value='private'>Private List ({itemDict.private.length})</Option>
                <Option value='purchased'>Purchased Items ({itemDict.purchased.length})</Option>
                <Option value='gifted'>Gifted Items ({itemDict.gifted.length})</Option>
                <Option value='deleted'>Deleted Items ({itemDict.deleted.length})</Option>
            </Select>
        }


        <ItemList
            sortMethod={sortMethods[sortBy]}
            triggerRefresh={triggerRefresh}
            settings={settings}
            items={isDisabled ? [] : itemDict[settings]}/>

        <Modal open={openHelp} onClose={() => setOpenHelp(false)}>
            <ModalDialog>
                <ModalClose/>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    {(!isDisabled && isUserId(userId) && family.length > 1) && <>
                        <KeyboardArrowDown color="primary"/>
                        <Typography sx={{marginBottom: 1}}>
                            Select managed account.
                        </Typography>
                    </>}
                    <AddBoxOutlined color="primary"/>
                    <Typography sx={{marginBottom: 1}}>
                        Add an item to this users list.
                    </Typography>
                    <FilterListSharp color="primary"/>
                    <Typography sx={{marginBottom: 1}}>
                        Select sorting options.
                    </Typography>
                    <OpenInNew color="primary"/>
                    <Typography sx={{marginBottom: 1}}>
                        Open link to item's website.
                    </Typography>
                    <AddShoppingCart color="primary"/>
                    <Typography sx={{marginBottom: 1}}>
                        Mark item as purchased.
                    </Typography>
                    <DoneAll color="primary"/>
                    <Typography sx={{marginBottom: 1}}>
                        Modify exchange date of purchase.
                    </Typography>
                    <ContentCopy color="primary"/>
                    <Typography sx={{marginBottom: 1}}>
                        Copy item to your list.
                    </Typography>
                    <Box sx={{display: 'flex'}}>
                        <FavoriteBorder color="primary"/>
                        &nbsp;
                        <Favorite color="primary"/>
                    </Box>
                    <Typography sx={{marginBottom: 1}}>
                        Medium and high priority items.
                    </Typography>
                    <Box sx={{display: 'flex'}}>
                        <VisibilitySharp color='primary'/>
                        &nbsp;
                        <VisibilityOff color='primary'/>
                    </Box>
                    <Typography sx={{marginBottom: 1}}>
                        {isUserId(userId) ? "For your eyes only" : `Hidden from ${user.firstName}`}
                    </Typography>
                </Box>
            </ModalDialog>
        </Modal>

        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={isDisabled}>
            <CircularProgress variant='plain' size='lg' thickness={5}/>
        </Backdrop>
    </>
}

export default UserItems;



