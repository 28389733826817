import * as React from 'react';
import {AppContext, ILoginResponse, IUserLogin, IUserRegister} from './NewAppContext';
import {SnackbarProvider} from 'notistack';
import {BrowserRouter} from "react-router-dom";
import Routes from './NewRoutes';
import axios, {AxiosResponse} from 'axios';
import Layout, {INavProp} from "../bensonAppModule/components/layout";
import {AppContextProvider} from "../utilities/AppContext";
import { BASE_API } from '../api/baseApi';

export interface IAuthentication {
    user: ILoginResponse;
    onUserInitialized: (value: boolean) => void;
    signIn: (data: IUserLogin) => void;
    signOut: () => void;
    register: (data: IUserLogin) => void;
}

export interface IToken {
    IsActive: string;
    email: string;
    exp: number;
    iat: number;
    nameid: string;
    nbf: number;
}

const defaultToken: IToken = {
    IsActive: "",
    email: "",
    exp: 0,
    iat: 0,
    nameid: "",
    nbf: 0
}

export const parseJwt = (token: string): IToken => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return {...defaultToken}
    }
};


export class Authentication implements IAuthentication {
    userKey = "userKey";
    user: any;
    onUserInitialized: (value: boolean) => void;

    constructor(onUserInitialized: (value: boolean) => void) {
        this.onUserInitialized = onUserInitialized;
        this.handleRefresh();
    }

    handleRefresh() {
        const userSessionStr = localStorage.getItem(this.userKey);
        if (userSessionStr == null) return;
        const userSessionJSON = JSON.parse(userSessionStr);
        this.AuthenticateUser(userSessionJSON);
    }


    async signIn(userLogin: IUserLogin) {
        try {
            const response: AxiosResponse<ILoginResponse> = await axios.post(`${BASE_API}/api/user/login`, userLogin);
            this.AuthenticateUser(response.data);
        } catch (e: any) {
            throw new Error(e)
        }
    }

    signOut() {
        localStorage.removeItem(this.userKey);
        delete axios.defaults.headers.common["Authorization"];

        this.user = null;
        this.onUserInitialized(true);
    }

    async register(data: IUserRegister) {
        try {
            await axios.post(`${BASE_API}/api/user/register`, data);
        } catch (e: any) {
            console.warn(e);
        }
    }

    private readToken(token: any = this.user.token) {
        return parseJwt(token)
    }

    private AuthenticateUser(user: ILoginResponse) {
        if (user == null || this.readToken(user.token).exp < Math.floor(Date.now() / 1000)) {
            localStorage.removeItem(this.userKey);
            return;
        }

        this.initUser(user);
        this.onUserInitialized(false);
    }

    private initUser(data: ILoginResponse) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
        localStorage.setItem(this.userKey, JSON.stringify(data));
        this.user = data;
    }
}

export default (props: { navProps?: INavProp[] }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [auth, setAuth] = React.useState<IAuthentication>();

    React.useEffect(() => {
        setAuth(new Authentication(setIsLoading));
    }, [])

    return (<AppContext.Provider value={{isLoading, auth}}>
            <AppContextProvider>
                <SnackbarProvider maxSnack={3}>
                    <Layout name="GiftGiver" navProps={props.navProps}>
                        <Routes/>
                    </Layout>
                </SnackbarProvider>
            </AppContextProvider>
        </AppContext.Provider>
    );
}