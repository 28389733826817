import React from "react";
import {IItem} from "../models/item";
import {ItemCard} from "./ItemCard";
import {Grid} from "@mui/joy";
import {settingsPref} from "../pages/UserItems";

interface IProps {
    items: IItem[];
    triggerRefresh: () => void;
    sortMethod: (a: IItem, b: IItem) => number;
    settings: settingsPref
}

export const ItemList = (props: IProps) => {
    return <Grid container spacing={1}>
        {
            props.items
                .sort(props.sortMethod)
                .map(u =>
                    <Grid xs={12} sm={6} md={4} lg={3} key={`${u.id}-item`}>
                        <ItemCard
                            triggerRefresh={props.triggerRefresh}
                            item={u}
                            settings={props.settings}/>
                    </Grid>)
        }
    </Grid>
}