import * as React from "react";
import {Switch, Route} from "react-router-dom";
import {AppContext} from "./NewAppContext";
import CreateEditItem from "../pages/CreateEditItem";
import Friends from "../pages/Friends";
import UserItems from "../pages/UserItems";
import SignIn from "../pages/Login";

export default () => {
    const context = React.useContext(AppContext);

    if (context.auth == null)
        return <div>Loading</div>
    else if (context.isLoading || context.auth.user == null)
        return <SignIn auth={context.auth}/>

    return (
        <Switch>
            <Route path="/addsuggestion/:userId/:itemId?" component={CreateEditItem}/>
            <Route path='/friends' component={Friends}/>
            <Route path="/:userId?" component={UserItems}/>
        </Switch>
    )
}