import {IItem} from "../models/item";
import React from "react";
import {AppContext} from "./AppContext";
import itemApi from "../api/itemApi";
import {settingsPref} from "../pages/UserItems";

const useItemList = (userId: string | null, refreshFlag: number): [{ [key: string]: IItem[] }, boolean] => {
    const {validators: {isUserId, isFor, isSuggestedBy, isPurchasedBy}} = React.useContext(AppContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [itemDict, setItemDict] = React.useState<{ [key: string]: IItem[] }>({
        current: [],
        purchased: [],
        gifted: [],
        deleted: [],
        private: []
    })

    const now = new Date();
    now.setDate(now.getDate() - 1);

    React.useEffect(() => {
        const controller = new AbortController();
        if (!userId || isNaN(parseInt(userId))) return;
        const ownList = isUserId(userId);
        const filterItems = (items: IItem[], settings: settingsPref) => {
            return items.filter(e => {
                if (settings === "current" || !ownList) {
                    if (e.isDeleted ||
                        e.isPrivate ||
                        (parseInt(userId) !== e.for.id) ||
                        (ownList && isUserId(e.purchasedBy?.id ?? null)) ||
                        (e.exchangeDate && new Date(e.exchangeDate) < new Date())) {
                        return false;
                    }
                } else if (settings === 'purchased' && (!e.purchasedBy || !isUserId(e.purchasedBy.id))) {
                    return false;
                } else if (settings === 'private' && (!e.isPrivate || e.isDeleted)) {
                    return false
                } else if (settings === "gifted") {
                    if (e.isDeleted || !isUserId(e.for.id) || !e.exchangeDate || (e.exchangeDate && new Date(e.exchangeDate) > new Date())) {
                        return false;
                    }
                } else if (settings === "deleted" && !e.isDeleted) {
                    return false;
                }

                if (e.isPrivate && !isFor(e)) {
                    return false;
                }

                if (isFor(e) && e.isPrivateSuggestion) {
                    return false;
                }

                if (isFor(e) || !e.purchaseDate) {
                    return true;
                }

                if (isSuggestedBy(e)) {
                    return true;
                }

                return isPurchasedBy(e);
            })
        }
        itemApi.GetByUserId(userId, controller.signal)
            .then(i => setItemDict({
                current: filterItems(i, 'current'),
                purchased: filterItems(i, 'purchased'),
                gifted: filterItems(i, 'gifted'),
                deleted: filterItems(i, 'deleted'),
                private: filterItems(i, 'private')
            }))
            .catch(console.warn)
            .finally(() => setIsLoading(false));
        return () => controller.abort();
    }, [userId, refreshFlag, isLoading, isUserId, isFor, isSuggestedBy, isPurchasedBy])
    return [itemDict, isLoading]
}

export default useItemList;