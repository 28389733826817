import * as React from 'react';
import {Box, Typography, IconButton, Container} from '@mui/joy';
import {Menu as MenuIcon} from '@mui/icons-material';
import {AppContext} from '../../newAuthModule/NewAppContext';
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {useHistory} from "react-router-dom";
import {AppBar, Menu, MenuItem, Toolbar} from "@mui/material";

export interface INavProp {
    Icon: OverridableComponent<any>;
    location: string;
    variant?: "solid" | "plain" | "outlined" | "soft" | undefined;
}

interface IProps {
    children: any;
    name: string;
    navProps?: INavProp[];
}

export default function Layout(props: IProps) {
    const context = React.useContext(AppContext);
    const history = useHistory();
    const signOut = () => context.auth?.signOut();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<Box>
        <AppBar>
            <Toolbar sx={{p: 0}}>
                <Container maxWidth="lg">
                    <Box component="main" sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography component="h6" sx={{marginLeft: 1, color: 'inherit'}}>
                            {props.name}
                        </Typography>

                        {
                            props.navProps ? props.navProps.map(({location, Icon, variant}, i) =>
                                <IconButton
                                    onClick={() => history.push(location.replace("{{USER_ID}}", context.auth?.user?.id || "NO"))}
                                    key={`button-${i}`}
                                    variant={variant || 'plain'}
                                    sx={{marginLeft: i === 0 ? 'auto' : 'inherit', color: 'inherit'}}
                                    size='sm'>
                                    <Icon/>
                                </IconButton>) : <></>
                        }

                        <IconButton
                            sx={{marginLeft: props.navProps ? 'inherit' : 'auto', color: 'inherit'}}
                            id="menu-button"
                            size="sm"
                            variant='plain'
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}>
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>

                            {/* <MenuItem
                                sx={{display: 'block', textAlign: 'right'}}
                                onClick={() => {
                                    window.location.replace(`${process.env.REACT_APP_AUTH_URL}/Account/UpdateUser/${context.auth?.user?.id}`)
                                }}>Update Account</MenuItem> */}

                            <MenuItem
                                sx={{display: 'block', textAlign: 'right'}}
                                onClick={signOut}>Logout</MenuItem>

                        </Menu>
                    </Box>
                </Container>
            </Toolbar>
        </AppBar>

        <Container maxWidth="lg">
            <Box component="main" sx={{flexGrow: 1, p: {xs: 0, sm: 1, md: 2}}}>
                <Toolbar/>
                {props.children}
            </Box>
        </Container>
    </Box>);
}