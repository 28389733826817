import React from "react";
import friendApi from "../api/friendApi";
import {UserList} from "../components/UserList";
import {IUser} from "../models/user";
import {
    Add, BackspaceOutlined,
    Close,
    FilterListSharp,
    PersonAdd,
    QuestionMark,
    RemoveCircleOutlineOutlined
} from "@mui/icons-material"
import {useMounted} from "../bensonAppModule/hooks/useIsMounted";
import {
    Badge,
    Box, Button, Card, CardContent, CircularProgress, FormControl, Grid,
    IconButton, Input, ListDivider,
    Modal,
    ModalClose,
    ModalDialog, Option, Select, Stack,
    Typography
} from "@mui/joy";
import {Backdrop, Menu, MenuItem} from "@mui/material";
import {IAvailableFriends} from "../models/friend";

interface IPrefs {
    sortDir: string;
}

const defaultPrefs: IPrefs = {
    sortDir: "bDay"
}

const PREFS = "FRIENDS";
const getPrefs = (): IPrefs => {
    const stored = sessionStorage.getItem(PREFS);
    if (stored == null) {
        sessionStorage.setItem(PREFS, JSON.stringify(defaultPrefs));
        return defaultPrefs;
    }
    return {...defaultPrefs, ...JSON.parse(stored)};
}

const sortBy: { [key: string]: (a: IUser | IAvailableFriends, b: IUser | IAvailableFriends) => number } = {
    "bDay": (a, b) => a.daysTillBirthday === b.daysTillBirthday ? a.lastName.toUpperCase() > b.lastName.toUpperCase() ? 1 : -1 : a.daysTillBirthday > b.daysTillBirthday ? 1 : -1,
    "fA": (a, b) => a.firstName.toUpperCase() === b.firstName.toUpperCase() ? a.lastName.toUpperCase() > b.lastName.toUpperCase() ? 1 : -1 : a.firstName.toUpperCase() > b.firstName.toUpperCase() ? 1 : -1,
    "fZ": (a, b) => a.firstName.toUpperCase() === b.firstName.toUpperCase() ? a.lastName.toUpperCase() < b.lastName.toUpperCase() ? 1 : -1 : a.firstName.toUpperCase() < b.firstName.toUpperCase() ? 1 : -1,
    "lA": (a, b) => a.lastName.toUpperCase() === b.lastName.toUpperCase() ? a.firstName.toUpperCase() > b.firstName.toUpperCase() ? 1 : -1 : a.lastName.toUpperCase() > b.lastName.toUpperCase() ? 1 : -1,
    "lZ": (a, b) => a.lastName.toUpperCase() === b.lastName.toUpperCase() ? a.firstName.toUpperCase() < b.firstName.toUpperCase() ? 1 : -1 : a.lastName.toUpperCase() < b.lastName.toUpperCase() ? 1 : -1,
}

type settings = 'add' | 'remove';

const Friends = () => {
    const prefs = getPrefs();
    const isMounted = useMounted();
    const [friends, setFriends] = React.useState<IUser[]>([]);
    const [options, setOptions] = React.useState<IAvailableFriends[]>([]);
    const [showGroupAdd, setShowGroupAdd] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isPending, setIsPending] = React.useState<boolean>(false);
    const [sortDir, setSortDir] = React.useState<string>(prefs.sortDir);
    const [openHelp, setOpenHelp] = React.useState<boolean>(false);
    const [settings, setSettings] = React.useState<settings>('add');
    const [searchStr, setSearchStr] = React.useState('');

    React.useEffect(() => {
        sessionStorage.setItem(PREFS, JSON.stringify({sortDir}))
    }, [sortDir])

    React.useEffect(() => {
        if (!isLoading) return;
        const controller = new AbortController();
        (async () => {
            try {
                const _friends = await friendApi.GetFriends(controller.signal);
                if (_friends.length === 0) {
                    setShowGroupAdd(true);
                    setSettings('add');
                }
                setFriends(_friends.sort(sortBy[sortDir]));
            } catch {
            } finally {
                setIsLoading(false);
            }
        })();
        return () => controller.abort();
    }, [sortDir, isLoading])

    const [hasFetchedOptions, setHasFetchedOptions] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (hasFetchedOptions) {
            return;
        }
        const controller = new AbortController();
        (async () => {
            try {
                const res = await friendApi.HasAvailableFriends(controller.signal);
                setOptions(res)
            } catch {
            } finally {
                setHasFetchedOptions(true);
            }
        })()
        return () => controller.abort();
    }, [hasFetchedOptions])

    const handleSelect = async (friendId: number) => {
        if (!friendId || isPending) return;
        setIsPending(true);
        try {
            const response = await friendApi.AddFriend({friendId});
            setFriends([response, ...friends].sort(sortBy[sortDir]));
            const _friends = options.filter(o => o.id !== friendId);
            if (_friends.length === 0) {
                setShowGroupAdd(false);
            }
            setOptions(_friends.sort(sortBy[sortDir]));
        } catch (error) {
            console.error(error);
        } finally {
            setIsPending(false);
        }
    }

    const removeFriend = async (friendId: number) => {
        if (!friendId || isPending) return;
        setIsPending(true);
        try {
            await friendApi.RemoveFriend(friendId);
            const fIndex = friends.findIndex(f => f.id === friendId);
            const _friend: IUser = {...friends[fIndex]};
            const _friends = friends.filter((_, i) => i !== fIndex);

            if (_friends.length === 0) {
                setSettings('add');
            }

            setFriends(_friends);
            setOptions([...options, {
                id: _friend?.id,
                firstName: _friend.firstName,
                lastName: _friend.lastName,
                daysTillBirthday: _friend.daysTillBirthday,
                birthday: _friend.birthday
            }]);
        } catch (error) {
            console.error(error)
        } finally {
            setIsPending(false);
        }
    }

    React.useEffect(() => {
        if (isMounted()) {
            setFriends(_friends => [..._friends.sort(sortBy[sortDir])])
        }
    }, [sortDir, isMounted])

    const BasicMenu = () => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const _open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <>
                <IconButton
                    size='sm'
                    id="basic-button"
                    aria-controls={_open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={_open ? 'true' : undefined}
                    onClick={handleClick}
                    color="primary"
                    variant='plain'
                    sx={{mr: '5px'}}>
                    <FilterListSharp/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={_open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <MenuItem
                        selected={sortDir === 'bDay'}
                        onClick={() => setSortDir("bDay")}>Days till B-Day</MenuItem>
                    <ListDivider/>
                    <MenuItem
                        selected={sortDir === 'fA'}
                        onClick={() => setSortDir("fA")}>First Name (A To Z)</MenuItem>
                    <MenuItem
                        selected={sortDir === 'fZ'}
                        onClick={() => setSortDir("fZ")}>First Name (Z To A)</MenuItem>
                    <ListDivider/>
                    <MenuItem
                        selected={sortDir === 'lA'}
                        onClick={() => setSortDir("lA")}>Last Name (A To Z)</MenuItem>
                    <MenuItem
                        selected={sortDir === 'lZ'}
                        onClick={() => setSortDir("lZ")}>Last Name (Z To A)</MenuItem>
                </Menu>
            </>
        );
    }
    return <>
        <Box borderBottom={2} mb={2} py={1} sx={{display: "flex"}}>
            <Typography
                component="h5"
                display="inline-flex"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                {!showGroupAdd ? "Friends" : "Manage Friends"}
            </Typography>
            <IconButton
                size='sm'
                color="primary"
                variant='plain'
                sx={{ml: 'auto', mr: '5px'}}
                disabled={showGroupAdd && friends.length === 0}
                onClick={() => {
                    setSettings('add');
                    setShowGroupAdd(!showGroupAdd);
                    setSearchStr('');
                }}>
                {!showGroupAdd ?
                    <Badge badgeContent={options.length} color='danger'>
                        <PersonAdd/>
                    </Badge> :
                    <Close/>
                }
            </IconButton>
            <BasicMenu/>
            <IconButton
                size='sm'
                color="primary"
                variant='plain'
                onClick={() => setOpenHelp(true)}>
                <QuestionMark/>
            </IconButton>
        </Box>

        <Grid container sx={{mb: 2}}>
            {
                showGroupAdd &&
                <Grid xs={6}>
                    <FormControl sx={{mr: 0.5}}>
                        <Select
                            value={settings}
                            onChange={(_, v) => setSettings(v!)}>
                            <Option value='add' disabled={options.length === 0}>Add ({options.length})</Option>
                            <Option value='remove' disabled={friends.length === 0}>Remove ({friends.length})</Option>
                        </Select>
                    </FormControl>
                </Grid>
            }
            <Grid xs={showGroupAdd ? 6 : 12}>
                <FormControl sx={{ml: showGroupAdd ? 0.5 : 0}}>
                    <Input
                        value={searchStr}
                        placeholder='Search for friends...'
                        endDecorator={<IconButton
                            size='sm'
                            variant='plain'
                            onClick={() => setSearchStr('')}>
                            <BackspaceOutlined/>
                        </IconButton>}
                        onChange={(e) => {
                            setSearchStr(e.target.value);
                        }}/>
                </FormControl>
            </Grid>
        </Grid>

        {
            !showGroupAdd ?
                <UserList users={friends.filter(o => {
                    return `${o.firstName.toUpperCase()}${o.lastName.toUpperCase()}`.includes(searchStr.toUpperCase());
                })}/>
                :
                <Stack spacing={1}>
                    {
                        (settings === 'add' ? options : friends)
                            .filter(o => {
                                return `${o.firstName.toUpperCase()}${o.lastName.toUpperCase()}`.includes(searchStr.toUpperCase());
                            })
                            .sort(sortBy[sortDir])
                            .map((o, i) =>
                                <Card size='sm' variant='outlined' key={`add-${i}`}>
                                    <CardContent sx={{d: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <div>
                                            <Typography component='h2'>
                                                {o.firstName}&nbsp;{o.lastName}
                                            </Typography>
                                            <Typography>
                                                {new Date(o.birthday).toLocaleString("en-US", {
                                                    month: "long",
                                                    day: "numeric"
                                                })}
                                            </Typography>
                                        </div>
                                        {
                                            settings === 'add' ?
                                                <Button
                                                    sx={{ml: 'auto'}}
                                                    variant='soft'
                                                    color='success'
                                                    disabled={isPending}
                                                    onClick={() => handleSelect(o.id)}>
                                                    <Add/>
                                                </Button>
                                                :
                                                <Button
                                                    sx={{ml: 'auto'}}
                                                    variant='soft'
                                                    color='danger'
                                                    disabled={isPending}
                                                    onClick={() => removeFriend(o.id)}>
                                                    <RemoveCircleOutlineOutlined/>
                                                </Button>
                                        }
                                    </CardContent>
                                </Card>)}
                </Stack>
        }


        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={isLoading}>
            <CircularProgress variant='plain' size='lg' thickness={5}/>
        </Backdrop>

        <Modal open={openHelp} onClose={() => setOpenHelp(false)}>
            <ModalDialog>
                <ModalClose/>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>

                    <PersonAdd color="primary"/>
                    <Typography sx={{marginBottom: 1}}>
                        Opens a menu to add or remove friends from your group page. Selecting the field will open a
                        drop
                        down which can be filtered by text input.
                    </Typography>

                    <FilterListSharp color="primary"/>
                    <Typography sx={{marginBottom: 1}}>
                        Opens a menu to select an order in which to sort your friends.
                    </Typography>

                </Box>
            </ModalDialog>
        </Modal>
    </>
}

export default Friends;