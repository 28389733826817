import * as React from "react";
import {IUser} from "../models/user";
import {AppContext as AuthContext} from "../newAuthModule/NewAppContext";
import usersApi from "../api/usersApi";
import {IItem} from "../models/item";

export interface IAuth {
    isAdmin: boolean;
}

export interface IAppContext {
    user?: IUser | null;
    family: IUser[];
    refresh: () => void;
    isLoading: boolean;
    validators: {
        isUserId: (userId: string | number | null, checkChildren?: boolean) => boolean;
        isFor: (item: IItem) => boolean,
        isPurchasedBy: (item: IItem) => boolean,
        isSuggestedBy: (item: IItem) => boolean,
    }
}

export const defaultAppContext: IAppContext = {
    user: null,
    isLoading: true,
    family: [],
    refresh: () => {
    },
    validators: {
        isUserId: (userId: string | number | null, checkChildren: boolean = true) => false,
        isFor: (item: IItem) => false,
        isPurchasedBy: (item: IItem) => false,
        isSuggestedBy: (item: IItem) => false,
    }
}

export const AppContext = React.createContext(defaultAppContext);

export enum DispatchActions {
    InitializeUser
}

type IAction = {
    type: DispatchActions,
    user?: IUser,
    users?: IUser[]
}

const reducer = (state: IAppContext, action: IAction): IAppContext => {
    switch (action.type) {
        case DispatchActions.InitializeUser:
            const family: IUser[] = [];

            if (action.user) {
                family.push(action.user)
                if (action.user.children) {
                    family.push(...action.user.children);
                }
                if (action.user.parent) {
                    family.push(action.user.parent);
                }
            }

            return {
                ...state,
                user: action.user,
                family
            }
        default:
            return state;
    }
}


export const AppContextProvider = (props: { children: any }) => {
    const {auth} = React.useContext(AuthContext)
    const [state, dispatch] = React.useReducer(reducer, {...defaultAppContext});
    const [triggerRefresh, setTriggerRefresh] = React.useState<number>(0);
    const [isLoading, setIsLoading] = React.useState(true);

    const refresh = () => setTriggerRefresh(triggerRefresh + 1);

    const isUserId = (userId: string | number | null, checkChildren: boolean = true): boolean => {
        if (!state.user) return false;
        if (typeof userId === "string") userId = parseInt(userId);
        if (state.user.id === userId) return true;

        if (checkChildren && state.user.children && state.user.children.length) {
            return state.user.children.some(u => u.id === userId)
        }

        return false;
    }

    const isFor = (i: IItem) => isUserId(i.for.id);
    const isPurchasedBy = (i: IItem) => isUserId(i.purchasedBy?.id ?? null);
    const isSuggestedBy = (i: IItem) => isUserId(i.suggestedBy?.id ?? null);

    React.useEffect(() => {
        if (!auth?.user?.id) return;

        setIsLoading(true);
        const controller = new AbortController();
        usersApi.GetUserById(auth?.user?.id, controller.signal)
            .then(user => {
                dispatch({type: DispatchActions.InitializeUser, user});
            })
            .finally(() => setIsLoading(false))

        return () => controller.abort();
    }, [auth?.user?.id, triggerRefresh])

    return <AppContext.Provider value={{
        ...state,
        refresh,
        isLoading,
        validators: {
            isFor,
            isUserId,
            isSuggestedBy,
            isPurchasedBy
        }
    }}>
        {props.children}
    </AppContext.Provider>
}