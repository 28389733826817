import * as React from 'react';
import Container from '@mui/material/Container';
import {Delete, DeleteForever, RestoreFromTrash, Close, Save as SaveIcon} from '@mui/icons-material';
import {useSnackbar} from 'notistack';
import {IItem, IItemCreateDto, priority} from '../models/item';
import {OpenGraphScraper} from '../utilities/openGraphScraper';
import itemApi from '../api/itemApi';
import {Link as RouterLink, useHistory, useLocation, useParams} from 'react-router-dom';
import {AppContext} from '../utilities/AppContext';
import {
    Box,
    FormControl,
    FormLabel,
    Typography,
    Input,
    Select,
    Stack,
    List,
    ListItem,
    Checkbox,
    Option,
    Button,
    Breadcrumbs,
    Link,
    IconButton,
    Grid,
    Textarea,
    CircularProgress,
    ModalDialog, ModalClose, Modal
} from '@mui/joy';
import {Backdrop} from "@mui/material";

interface ILocation extends IItem {
    userName?: string;
    returnUserId?: string;
}

export default function CreateEditItem() {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {validators: {isUserId}} = React.useContext(AppContext);
    const history = useHistory();
    const location = useLocation<ILocation | null>();
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const {itemId, userId} = useParams<{ itemId?: string, userId: string }>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const isOwnItem = isUserId(userId);
    const [item, setItem] = React.useState<IItemCreateDto>({
        name: location.state?.name || "",
        note: location.state?.note || "",
        description: location.state?.description || "",
        imageUrl: location.state?.imageUrl || "",
        imageType: location.state?.imageType || "",
        link: location.state?.link || "",
        siteName: location.state?.siteName || "",
        price: location.state?.price || 0,
        priority: location.state?.priority || 0,
        isPersistent: location.state?.isPersistent || false,
        isPrivate: location.state?.isPrivate || false,
        isPrivateSuggestion: location.state?.isPrivateSuggestion || false,
        isDeleted: location.state?.isDeleted || false
    });

    React.useEffect(() => {
        if (!itemId) return;
        const controller = new AbortController();
        (async () => {
            try {
                const response = await itemApi.GetById(itemId, controller.signal);
                setItem(response);
            } catch (e: any) {
                enqueueSnackbar(e.toString());
            }
        })();
        return () => controller.abort();
    }, [itemId, enqueueSnackbar]);

    const handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.name === "link") {
            const i = event.target.value.indexOf("http");
            if (i > 0) {
                event.target.value = event.target.value.slice(i);
            }
        }
        if (event.target.type === "checkbox") {
            setItem(prev => ({
                ...prev,
                [event.target.name]: !(prev as any)[event.target.name]
            }))
            return;
        }
        setItem(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    const [isSearching, setIsSearching] = React.useState<boolean>(false);
    React.useEffect(() => {
        (async () => {
            if (!item.link || item.siteName || item.link.indexOf("http") !== 0) return;
            try {
                setIsSearching(true);
                enqueueSnackbar("Fetching Image");
                const {hybridGraph} = await OpenGraphScraper(item.link);
                closeSnackbar();
                setItem(prev => ({
                    ...prev,
                    siteName: hybridGraph.site_name,
                    description: hybridGraph.description,
                    imageType: hybridGraph.imageType,
                    imageUrl: typeof hybridGraph.image === "string" ? hybridGraph.image : hybridGraph.image?.url,
                }));
            } catch (e: any) {
                setItem(prev => ({...prev, siteName: "N/A"}))
                enqueueSnackbar(e.toString());
            } finally {
                setIsSearching(false);
            }
        })()
    }, [item.link, item.siteName, enqueueSnackbar, closeSnackbar])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (isLoading) return;
        setIsLoading(true);
        const controller = new AbortController();
        try {
            item.price = Math.round(item.price);
            if (itemId) {
                await itemApi.Update({...item, isDeleted: false, id: parseInt(itemId)}, controller.signal);
            } else if (isOwnItem) {
                await itemApi.Create(item, controller.signal);
            } else {
                await itemApi.CreateSuggestion(parseInt(userId), item, controller.signal);
            }

            if (!isOwnItem && location.state && "returnUserId" in location.state) {
                history.push(`/${location.state?.returnUserId!}`)
            } else {
                history.push(`/${userId}`)
            }
        } catch (e: any) {
            enqueueSnackbar(e.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async () => {
        if (isDisabled || !itemId) return;
        const controller = new AbortController();
        try {
            setIsDisabled(true);
            if (item.isDeleted) {
                await itemApi.HardDelete(itemId, controller.signal);
            } else {
                await itemApi.Delete(itemId, controller.signal);
            }
            if (!isOwnItem && location.state && "returnUserId" in location.state) {
                history.push(`/${location.state?.returnUserId!}`)
            } else {
                history.push(`/${userId}`)
            }
        } catch (e: any) {
            const r = e.response.data.toString();
            const start = r.search(":");
            const end = r.search("at");
            const msg = r.slice(start + 2, end);
            enqueueSnackbar(msg);
        } finally {
            setIsDisabled(true);
        }
    }

    return (
        <>
            <Box borderBottom={2} py={1} mb={2} sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Breadcrumbs sx={{padding: 0}}>
                    {
                        !isOwnItem &&
                        <Link component={RouterLink} to='/friends' fontWeight='bold'>
                            Friends
                        </Link>
                    }
                    <Link component={RouterLink} to={`/${userId}`} fontWeight='bold'>
                        {location.state?.userName ?? userId}
                    </Link>
                    <Typography component="h5">
                        {itemId ? "Edit" : "Add"}&nbsp;Item
                    </Typography>
                </Breadcrumbs>

                <IconButton
                    size='sm'
                    variant='soft'
                    color="danger"
                    sx={{marginLeft: 'auto', opacity: itemId ? 1 : 0}}
                    disabled={!itemId}
                    onClick={() => setOpenDeleteModal(true)}>
                    {item.isDeleted ? <DeleteForever/> : <Delete/>}
                </IconButton>
            </Box>
            <Container component="main" maxWidth="xs" sx={{padding: 0}}>
                <Box sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Box component="form" onSubmit={handleSubmit} sx={{width: '100%'}}>
                        <Stack spacing={1}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input name='name'
                                       onChange={handleInput}
                                       value={item.name}
                                       disabled={isSearching}/>
                            </FormControl>

                            <Grid container>
                                <Grid xs={6}>
                                    <FormControl sx={{marginRight: 1}}>
                                        <FormLabel required={true}>Price</FormLabel>
                                        <Input
                                            required
                                            name="price"
                                            type="number"
                                            onChange={handleInput}
                                            value={item.price || ""}
                                            startDecorator='$'
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid xs={6}>
                                    <FormControl sx={{marginLeft: 1}}>
                                        <FormLabel>Priority</FormLabel>
                                        <Select
                                            id="priority-select"
                                            value={item.priority}
                                            onChange={(_, v) => {
                                                setItem(i => ({...i, priority: v ?? i.priority}))
                                            }}>
                                            <Option value={priority.Low}>{priority[priority.Low]}</Option>
                                            <Option value={priority.Medium}>{priority[priority.Medium]}</Option>
                                            <Option value={priority.High}>{priority[priority.High]}</Option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <FormControl>
                                <FormLabel>Link</FormLabel>
                                <Input name='link'
                                       onChange={handleInput}
                                       value={item.link}
                                       disabled={isSearching}
                                       endDecorator={<IconButton
                                           size='sm'
                                           variant='plain'
                                           onClick={() => setItem({
                                               ...item,
                                               link: ''
                                           })}>
                                           <Close/>
                                       </IconButton>}/>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Image Link</FormLabel>
                                <Input name='imageUrl'
                                       onChange={handleInput}
                                       value={item.imageUrl}
                                       disabled={isSearching}
                                       endDecorator={<IconButton
                                           size='sm'
                                           variant='plain'
                                           onClick={() => setItem({
                                               ...item,
                                               imageUrl: ''
                                           })}>
                                           <Close/>
                                       </IconButton>}/>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Note</FormLabel>
                                <Textarea
                                    name="note"
                                    onChange={handleInput}
                                    value={item.note ?? ""}
                                    minRows={2}
                                />
                            </FormControl>

                            <Box role='group'>
                                {
                                    isOwnItem ?
                                        <List size='sm'>
                                            <ListItem>
                                                <Checkbox
                                                    name="isPrivate"
                                                    label="Item only visible in your list"
                                                    onChange={handleInput}
                                                    checked={item.isPrivate}/>
                                            </ListItem>
                                        </List> :
                                        <List size='sm'>
                                            <ListItem>
                                                <Checkbox
                                                    name="isPrivateSuggestion"
                                                    label="Private Suggestion"
                                                    onChange={handleInput}
                                                    checked={item.isPrivateSuggestion}/>
                                            </ListItem>
                                        </List>
                                }
                                <List size='sm'>
                                    <ListItem>
                                        <Checkbox
                                            name="isPersistent"
                                            label="Allow multiple purchases"
                                            onChange={handleInput}
                                            checked={item.isPersistent}/>
                                    </ListItem>
                                </List>
                            </Box>

                            <Grid container>
                                <Grid xs={6}>
                                    <Button
                                        onClick={() => {
                                            if (location.state && "returnUserId" in location.state) {
                                                history.push(`/${location.state?.returnUserId!}`)
                                            } else {
                                                history.goBack();
                                            }
                                        }}
                                        sx={{mr: 1}}
                                        variant='soft'
                                        fullWidth
                                        startDecorator={<Close/>}
                                        color='neutral'>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid xs={6}>
                                    <Button
                                        type="submit"
                                        loading={isSearching || isLoading}
                                        disabled={isSearching || isLoading}
                                        loadingPosition="start"
                                        startDecorator={item.isDeleted ? <RestoreFromTrash/> : <SaveIcon/>}
                                        variant="soft"
                                        fullWidth
                                        sx={{ml: 1}}
                                    >
                                        {!itemId ? "Add" : item.isDeleted ? "Restore" : "Edit"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </Box>
            </Container>
            <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{zIndex: 5}}>
                <ModalDialog size='sm'>
                    <ModalClose/>
                    <Typography component='h2'>Delete Item</Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '524px'
                    }}>
                        <Stack spacing={2}>
                            <Typography>
                                Are you sure you would like to delete this item {item.isDeleted && "permanently"}?
                            </Typography>
                            <Button
                                variant='soft'
                                color="danger"
                                disabled={isDisabled}
                                loading={isDisabled}
                                onClick={handleDelete}>
                                Delete
                            </Button>
                        </Stack>
                    </Box>
                </ModalDialog>
            </Modal>
            <Backdrop
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isSearching || isLoading}>
                <CircularProgress variant='plain' size='lg' thickness={5}/>
            </Backdrop>
        </>
    )
}