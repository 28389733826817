import axios from "axios";
import {IItem, IItemCreateDto, IItemPurchase, IItemUpdateDto} from "../models/item";
import BaseApi, { BASE_API } from "./baseApi";

class ItemApi extends BaseApi<IItem> {
    constructor() {
        super("item")
    }

    async GetById(id: number | string, signal: AbortSignal) {
        const response = await axios.get<IItem>(`${BASE_API}/api/item/${id}`, {signal});
        return response.data;
    }

    async GetByUserId(id: number | string, signal: AbortSignal) {
        const response = await axios.get<IItem[]>(`${BASE_API}/api/item/users/${id}`, {signal});
        return response.data;
    }

    async CreateSuggestion(id: number | string, item: IItemCreateDto, signal: AbortSignal) {
        return await axios.post(`${BASE_API}/api/item/suggestion/${id}`, item, {signal});
    }

    async Create(item: IItemCreateDto, signal: AbortSignal) {
        return await axios.post(`${BASE_API}/api/item`, item, {signal});
    }

    async Update(item: IItemUpdateDto, signal?: AbortSignal) {
        return await axios.put(`${BASE_API}/api/item`, item, {signal});
    }

    async Delete(itemId: number | string, signal: AbortSignal) {
        return await axios.delete(`${BASE_API}/api/item/${itemId}`, {signal});
    }

    async HardDelete(itemId: number | string, signal: AbortSignal) {
        return await axios.delete(`${BASE_API}/api/item/${itemId}/hard`, {signal});
    }

    async RemovePurchase(itemId: number | string, signal: AbortSignal) {
        return await axios.post(`${BASE_API}/api/item/remove/${itemId}`, {signal});
    }

    async Purchase(item: IItemPurchase, signal: AbortSignal) {
        return await axios.post(`${BASE_API}/api/item/purchase`, item, {signal});
    }
}

const api = new ItemApi();
export default api;