import {IUser} from "./user";

export enum priority {
    Low,
    Medium,
    High
}


export interface IItem {
    id: number;
    name: string;
    price: number;
    priority: priority;
    link?: string;
    note?: string;
    description?: string;
    imageUrl?: string;
    imageType?: string;
    siteName?: string;
    for: IUser;
    suggestedBy?: IUser;
    purchasedBy?: IUser;
    purchaseDate?: string;
    exchangeDate?: string;
    updatedAtDate: string;
    createdOnDate: string;
    isDeleted: boolean;
    isPrivate: boolean;
    isPersistent: boolean;
    isPrivateSuggestion: boolean;
}

export interface IItemCreateDto {
    name: string;
    price: number;
    priority: priority;
    link?: string;
    note?: string;
    description?: string;
    imageUrl?: string;
    imageType?: string;
    siteName?: string;
    isPrivate: boolean;
    isPersistent: boolean;
    isPrivateSuggestion: boolean;
    isDeleted: boolean;
}

export interface IItemUpdateDto extends IItemCreateDto {
    id: number;
}

export interface IItemPurchase {
    id: number;
    exchangeDate: string;
}