import axios from "axios";
import { IAvailableFriends, ICreateFriendDto } from "../models/friend";
import { IUser } from "../models/user";
import BaseApi, { BASE_API } from "./baseApi";
import { execute } from "../bensonAppModule/api/baseApi";

class FriendApi extends BaseApi<IUser> {
    constructor() {
        super("friend");
    }

    AddFriend(createFriendDto: ICreateFriendDto, signal?: AbortSignal) {
        return execute(() => axios.post<IUser>(`${BASE_API}/api/friend`, createFriendDto, { signal }))
    }

    RemoveFriend(friendId: number, signal?: AbortSignal) {
        return execute(() => axios.delete(`${BASE_API}/api/friend/${friendId}`, { signal }))
    }

    GetFriends(signal: AbortSignal) {
        return execute(() => axios.get<IUser[]>(`${BASE_API}/api/friend`, { signal }));
    }

    HasAvailableFriends(signal: AbortSignal) {
        return execute(() => axios.get<IAvailableFriends[]>(`${BASE_API}/api/friend/available`, { signal }));
    }
}

const api = new FriendApi();
export default api;