import {IUser} from "../models/user";
import {useHistory} from "react-router-dom";
import {Badge, Button, Card, CardContent, Typography} from "@mui/joy";
import {Add, FormatListBulleted} from "@mui/icons-material";

interface IProps {
    user: IUser;
}

export const UserCard = (props: IProps) => {
    const history = useHistory();
    return <Card size='sm' variant='outlined'>
        <CardContent sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <div>
                <Typography component='h2'>{props.user.firstName} {props.user.lastName}</Typography>
                <Typography>
                    {new Date(props.user.birthday).toLocaleString("en-US", {
                        month: "long",
                        day: "numeric"
                    })}
                </Typography>
            </div>
            <Badge badgeContent={props.user.itemCount} color='danger' sx={{ml: 'auto', mr: 1}}>
                <Button
                    color={props.user.itemCount ? 'success' : 'neutral'}
                    variant='soft'
                    onClick={() => history.push(`/${props.user.id}`, props.user)}>
                    <FormatListBulleted/>
                </Button>
            </Badge>
        </CardContent>
    </Card>
}