import axios from "axios";
import {IUser, IUserCreateDto, IUserUpdateDto} from "../models/user";
import BaseApi, { BASE_API } from "./baseApi";
import {execute} from "../bensonAppModule/api/baseApi";

class UsersApi extends BaseApi<IUser> {
    constructor() {
        super("user");
    }

    CreateChild(user: IUserCreateDto, signal: AbortSignal) {
        return execute(() => axios.post<IUser>(`${BASE_API}/api/user/child`, user, {signal}));
    }

    Update(user: IUserUpdateDto, signal: AbortSignal) {
        return execute(() => axios.put<IUser>(`${BASE_API}/api/user`, user, {signal}));
    }

    GetUserById(id: number | string, signal: AbortSignal) {
        return execute(() => axios.get<IUser>(`${BASE_API}/api/user/${id}`, {signal}));
    }

    GetFriends(signal: AbortSignal) {
        return execute(() => axios.get<IUser[]>(`${BASE_API}/api/user/friends`, {signal}));
    }
}

const api = new UsersApi();
export default api;