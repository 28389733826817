import {AxiosError, AxiosResponse} from "axios";

function execute<X>(method: () => Promise<AxiosResponse<X, any>>): Promise<X> {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await method();
            resolve(response.data)
        } catch (error) {
            if ((error as AxiosError).response?.status === 401) {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
            }
            reject(error)
        }
    })
}

export {
    execute
}
