import * as React from "react";

export interface IUserLogin {
    email: string;
    password: string;
}

export interface ILoginResponse {
    id: string;
    email: string;
    token: string;
}

export interface IUserRegister {
    email: string;
    password: string;
}

export interface IAuthentication {
    user: ILoginResponse;
    onUserInitialized: (value: boolean) => void;
    signIn: (data: IUserLogin) => void;
    signOut: () => void;
    register: (data: IUserLogin) => void;
}

export interface IAuth {
    isAdmin: boolean;
}

export interface IAppContext {
    auth?: IAuthentication;
    isLoading: boolean;
}

export const defaultAppContext: IAppContext = {
    auth: undefined,
    isLoading: true
}

export const AppContext = React.createContext(defaultAppContext);