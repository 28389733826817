import * as React from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {FormLabel, FormControl, Input} from "@mui/joy";

interface IProps {
    date: string;
    setValue: (args: string) => void;
    label?: string;
}

export default function BasicDatePicker(props: IProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={props.date}
                onChange={e => e && props.setValue(e)}
                renderInput={({inputRef, inputProps, InputProps}) => (
                    <FormControl>
                        <FormLabel>{props.label}</FormLabel>
                        <Input ref={inputRef} value={inputProps?.value} endDecorator={InputProps?.endAdornment}/>
                    </FormControl>
                )}
            />
        </LocalizationProvider>
    );
}