import axios, { AxiosResponse } from "axios";

export const BASE_API = "https://giftgiverfunc.azurewebsites.net";

export default class BaseApi<T> {
    entity: string;

    constructor(entity: string) {
        this.entity = `https://giftgiverfunc.azurewebsites.net/api/${entity}`;
    }

    async Get(signal: AbortSignal) {
        const response: AxiosResponse<T[]> = await axios.get(this.entity, { signal });
        return response.data;
    }

    // Execute<X>(method: () => Promise<AxiosResponse<X, any>>): Promise<X> {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             const response = await method();
    //             resolve(response.data)
    //         } catch (error) {
    //             reject(error)
    //         }
    //     })
    // }
}