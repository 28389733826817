import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';
import { IAuthentication } from '../newAuthModule/NewAppContext';

interface IProps {
    auth: IAuthentication;
    redirect: () => void;
}

export interface IUserRegister {
    email: string;
    password: string;
}

export default function Register(props: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [userLogin, setUserLogin] = React.useState<IUserRegister>({
        email: "",
        password: ""
    });

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserLogin(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await props.auth.register(userLogin);
            props.redirect();
        } catch (e: any) {
            enqueueSnackbar(e.toString());
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Register
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleInput}
                        value={userLogin.email}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleInput}
                        value={userLogin.password}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Submit
                    </Button>
                    <Typography
                        sx={{ textAlign: "center" }}
                        onClick={props.redirect}
                    >
                        Sign In
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}