import React from 'react';
import {People, Person} from "@mui/icons-material";
import NewApp from "./newAuthModule/NewApp";

export const App = () => {
    return (
        <NewApp navProps={[
            {
                Icon: Person,
                location: `/{{USER_ID}}`
            },
            {
                Icon: People,
                location: "/friends"
            },
        ]}/>);
}
