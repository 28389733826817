import axios from "axios";

const API_KEY_INDEX = "API_KEY_INDEX";
const API_KEYS = [
    '2f1ecd37-b7a8-4079-b22e-1b25a86ea4ab',
    '69f83825-98eb-42eb-b4cd-0548055c674d',
    '177c9303-4638-45cb-add0-910cc25480e8',
    'df04a0d0-24ec-472b-9be6-53ca231a873b',
    'c94fbd8d-ce7e-4155-bedf-0f1cb6a2e48d',
    'bada976a-7a0b-4717-b4ea-123c8e53de40'
]

type imageObject = {
    url?: string;
    type?: string;
}

interface IGraphObject {
    title?: string;
    description?: string;
    type?: string;
    locale?: string;
    image?: string | imageObject;
    imageType?: string;
    url?: string;
    products?: any[];
    favicon?: string;
    site_name?: string;
    images?: string[];
}

interface IOpenGraphResult {
    hybridGraph: IGraphObject;
    openGraph: IGraphObject;
    htmlInferred: IGraphObject;
    requestInfo: object;
    accept_lang: string;
    is_cache: boolean;
    url: string;
}

const waitFor = (ms: number) => new Promise((r) => setTimeout(r, ms));

const retry = (promise: any, onRetry: () => void, maxRetries: number = 3) => {
    const retryWithBackoff = async (retries: number): Promise<any> => {
        try {
            if (retries > 0) {
                const timeToWait = 2 ** retries * 100;
                await waitFor(timeToWait);
            }
            return await promise();
        } catch (error) {
            if (retries < maxRetries) {
                onRetry();
                return retryWithBackoff(retries + 1);
            }
            throw error;
        }
    }
    return retryWithBackoff(0);
}

export const OpenGraphScraper = (url: string) => {
    const encodedURL = encodeURIComponent(url).trim();
    const _index = localStorage.getItem(API_KEY_INDEX) ?? Math.floor(Math.random() * API_KEYS.length).toString();
    let index = parseInt(_index);

    const scraper = () => new Promise<IOpenGraphResult>(async (resolve, reject) => {
        if (!encodedURL || encodedURL.length === 0) reject(`${url}\n${encodedURL}`);
        const KEY = API_KEYS[index];
        index++;
        index %= API_KEYS.length;
        localStorage.setItem(API_KEY_INDEX, index.toString());
        try {
            const response = await axios.get<IOpenGraphResult>(`https://opengraph.io/api/1.1/site/${encodedURL}?app_id=${KEY}`);
            if (!response.data.hybridGraph.image) return reject("No image defined");
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    })
    const onRetry = () => console.log("retry called");
    return retry(scraper, onRetry);
}