import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/joy';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';
import { IAuthentication } from "../newAuthModule/NewApp";
import Register from "./Register";

interface IProps {
    auth: IAuthentication;
}

export interface IUserLogin {
    email: string;
    password: string;
}

export interface IUserDto {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    middleInitial: string;
    email: string;
    isActive: boolean;
}

export interface ILoginResponse {
    email: string;
    token: string;
}

export default function SignIn(props: IProps) {
    const { enqueueSnackbar } = useSnackbar();
    const [isPending, setIsPending] = React.useState(false);
    const [showRegister, setShowRegister] = React.useState<boolean>(false);
    const [userLogin, setUserLogin] = React.useState<IUserLogin>({
        email: "",
        password: ""
    });

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserLogin(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsPending(true);
        try {
            await props.auth.signIn(userLogin);
        } catch (e: any) {
            enqueueSnackbar(e.toString());
        } finally {
            setIsPending(false);
        }
    };

    if (showRegister) return <Register auth={props.auth} redirect={() => setShowRegister(false)} />

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleInput}
                        value={userLogin.email}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleInput}
                        value={userLogin.password}
                    />
                    <Button
                        disabled={isPending}
                        loading={isPending}
                        type="submit"
                        fullWidth
                        variant="solid"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    <Typography
                        sx={{ textAlign: "center" }}
                        onClick={() => setShowRegister(true)}
                    >
                        Register
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}