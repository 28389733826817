import React from "react";
import {
    OpenInNew,
    DoneAll,
    AddShoppingCart,
    VisibilityOff,
    ContentCopy,
    FavoriteBorder,
    Favorite, Edit
} from '@mui/icons-material'

import {
    Card,
    CardCover,
    CardContent,
    Typography,
    IconButton,
    Button,
    Box,
    Tooltip, ModalDialog, Modal, Select, Option,
    FormControl, FormLabel, Stack, ModalClose
} from '@mui/joy'

import {IItem, priority} from "../models/item";
import {useSnackbar} from 'notistack';
import itemApi from "../api/itemApi";
import {useHistory} from "react-router-dom";
import {AppContext} from "../utilities/AppContext";
import BasicDatePicker from "../bensonAppModule/components/basicDatePicker";
import {settingsPref} from "../pages/UserItems";

interface IProps {
    item: IItem;
    triggerRefresh: () => void;
    settings: settingsPref
}

const christmasDate = new Date();
christmasDate.setMonth(11);
christmasDate.setDate(25);

const birthdayDate = (_birthday: string) => {
    const today = new Date();
    let year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();

    const birthday = new Date(_birthday);
    const birthdayMonth = birthday.getMonth();
    const birthdayDay = birthday.getDate();

    if (birthdayMonth > month || (birthdayMonth === month && birthdayDay >= day)) {
        birthday.setFullYear(year);
    } else {
        birthday.setFullYear(year + 1);
    }

    return birthday.toDateString();
}

export const ItemCard = (props: IProps) => {
    const history = useHistory();
    const {user, validators: {isUserId}} = React.useContext(AppContext);
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = React.useState<boolean>(false);
    const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
    const [exchangeDate, setExchangeDate] = React.useState<string>(() => {
        if (props.item.purchasedBy && props.item.exchangeDate) {
            return props.item.exchangeDate;
        }
        return birthdayDate(props.item.for.birthday);
    });

    const [alignment, setAlignment] = React.useState(() => {
        if (!props.item.exchangeDate) return 'birthday';

        const _exchangeDate = new Date(props.item.exchangeDate);
        const _month = _exchangeDate.getMonth();
        const _date = _exchangeDate.getDate();

        if (_month === 11 && _date === 25) return 'christmas';

        const _birthday = new Date(props.item.for.birthday);
        const _bMonth = _birthday.getMonth();
        const _bDate = _birthday.getDate();

        if (_bMonth === _month && _bDate === _date) return 'birthday';

        return 'custom'
    });

    const handleClick = () => setOpen(!open);

    const handlePurchased = async () => {
        if (isDisabled) return;
        const controller = new AbortController();
        try {
            setIsDisabled(true);
            await itemApi.Purchase({
                id: props.item.id,
                exchangeDate
            }, controller.signal)
            props.triggerRefresh();
        } catch (e: any) {
            const r = e.response.data.toString();
            const start = r.search(":");
            const end = r.search("at");
            const msg = r.slice(start + 2, end);
            enqueueSnackbar(msg);
        } finally {
            setIsDisabled(false);
        }
    }

    const handleRemovePurchase = async () => {
        if (isDisabled) return;
        const controller = new AbortController();
        try {
            setIsDisabled(true);
            await itemApi.RemovePurchase(props.item.id, controller.signal);
            props.triggerRefresh();
        } catch (e: any) {
            const r = e.response.data.toString();
            const start = r.search(":");
            const end = r.search("at");
            const msg = r.slice(start + 2, end);
            enqueueSnackbar(msg);
        } finally {
            setIsDisabled(false);
        }
    }    

    const handleDatePicker = (date: string) => {
        setAlignment("custom");
        setExchangeDate(date);
    }

    React.useEffect(() => {
        switch (alignment) {
            case "birthday":
                setExchangeDate(birthdayDate(props.item.for.birthday));
                break;
            case "christmas":
                setExchangeDate(christmasDate.toLocaleDateString());
                break;
        }
    }, [alignment, props.item.for.birthday])

    return <React.Fragment>
        <Card sx={{minHeight: '200px'}} variant='outlined'>
            <CardCover>
                <img
                    src={props.item.imageUrl || "https://static3.depositphotos.com/1006009/206/i/600/depositphotos_2061693-stock-photo-no-image-available-text-on.jpg"}
                    alt={props.item.description}
                    loading="lazy"
                />
            </CardCover>
            <CardCover
                sx={{background: 'linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0) 175px)'}}/>
            <CardContent sx={{justifyContent: 'flex-end'}}>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                    <Typography
                        fontSize="xl"
                        textColor="#fff"
                        onClick={() => window.open(props.item.link)}>
                        {props.item.name}: ${props.item.price}
                    </Typography>
                </Box>
                {
                    props.item.note &&
                    <Typography textColor="#fff" fontSize='sm'>
                        {props.item.note}
                    </Typography>
                }
                {
                    props.item.suggestedBy &&
                    <Typography textColor='#FFF' fontSize='xs'>
                        Suggested By: {props.item.suggestedBy.firstName} {props.item.suggestedBy.lastName[0]}.
                    </Typography>
                }
                {
                    props.item.purchasedBy &&
                    !!props.item.exchangeDate &&
                    props.settings !== 'purchased' &&
                    (isUserId(props.item.purchasedBy.id) || user?.id === 1) &&
                    <Typography textColor='#FFF' fontSize='xs'>
                        Gifted
                        By: {props.item.purchasedBy.firstName} {props.item.purchasedBy.lastName[0]} on {new Date(props.item.exchangeDate).toLocaleDateString()}
                    </Typography>
                }
                {
                    props.item.for && props.settings === 'purchased' && !!props.item.exchangeDate &&
                    <Typography textColor='#FFF' fontSize='xs'>
                        Purchased
                        For: {props.item.for.firstName} {props.item.for.lastName[0]} on {new Date(props.item.exchangeDate).toLocaleDateString()}
                    </Typography>
                }
                <Box m={0} p={0} sx={{width: "100%", display: "flex"}}>
                    {
                        props.item.link &&
                        <Tooltip title="View Item" placement="top" arrow>
                            <IconButton
                                size='sm'
                                variant='plain'
                                onClick={() => window.open(props.item.link)}>
                                <OpenInNew sx={{color: "#fff"}}/>
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        isUserId(props.item.for.id, false) || props.item.isPersistent ? <></> :
                            props.item.purchasedBy == null ?
                                <Tooltip title="Purchase this item?" placement="top" arrow>
                                    <IconButton size='sm' variant='plain' onClick={handleClick}>
                                        <AddShoppingCart sx={{color: "#fff"}}/>
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip title="Modify purchase?" placement="top" arrow>
                                    <span>
                                    <IconButton
                                        size='sm'
                                        variant='plain'
                                        onClick={handleClick}
                                        disabled={!isUserId(props.item.purchasedBy?.id)}>
                                        <DoneAll sx={{color: "#fff"}}/>
                                    </IconButton>
                                    </span>
                                </Tooltip>

                    }
                    {
                        !isUserId(props.item.for?.id) &&
                        <Tooltip title="Copy to own list?" placement="top" arrow>
                            <IconButton
                                size='sm'
                                variant='plain'
                                onClick={() => history.push(`/addsuggestion/${user?.id}`, {
                                    ...props.item,
                                    userName: `${user?.firstName} ${user?.lastName[0]}.`
                                })}>
                                <ContentCopy sx={{color: "#fff"}}/>
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        props.item.priority === priority.Low ? <></> :
                            props.item.priority === priority.Medium ?
                                <Tooltip title="I like this!" placement="top" arrow>
                                    <IconButton size='sm' variant='plain' disabled={true}>
                                        <FavoriteBorder sx={{color: 'white'}}/>
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip title="I LOVE this!" placement="top" arrow>
                                    <IconButton size='sm' variant='plain' disabled={true}>
                                        <Favorite sx={{color: "#fff"}}/>
                                    </IconButton>
                                </Tooltip>
                    }
                    {(props.item.isPrivateSuggestion || props.item.isPrivate) &&
                        <Tooltip title="Hidden Item" placement="top" arrow>
                            <IconButton size='sm' variant='plain' sx={{marginLeft: "auto"}} disabled={true}>
                                <VisibilityOff sx={{color: "#fff"}}/>
                            </IconButton>
                        </Tooltip>}
                    {(isUserId(props.item.for.id) || isUserId(props.item.suggestedBy?.id ?? null))
                        && props.settings !== 'purchased' &&
                        <Tooltip title="Hidden Item" placement="top" arrow>
                            <IconButton size='sm' variant='plain' sx={{
                                marginLeft: (props.item.isPrivateSuggestion || props.item.isPrivate) ? "inherit" : "auto"
                            }}>
                                <Edit sx={{color: "#fff"}} onClick={() => {
                                    history.push(`/addsuggestion/${props.item.for.id}/${props.item.id}`, {
                                        ...props.item,
                                        userName: `${props.item.for.firstName} ${props.item.for.lastName[0]}.`
                                    })
                                }}/>
                            </IconButton>
                        </Tooltip>}
                </Box>
            </CardContent>
        </Card>

        <Modal open={open} onClose={handleClick} sx={{zIndex: 5}}>
            <ModalDialog size='sm'>
                <ModalClose/>
                <Typography component='h2'>Gift Exchange Date</Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '524px'
                }}>
                    <Stack spacing={2}>
                        <Typography>
                            When will this gift be given? Until this date, the item will still appear on the user's
                            wishlist
                            for themselves, but will be hidden from other users.
                        </Typography>

                        <FormControl>
                            <FormLabel>Occasion</FormLabel>
                            <Select value={alignment} onChange={(_, v) => setAlignment(v ?? 'custom')}>
                                <Option value='birthday'>Birthday</Option>
                                <Option value='christmas'>Christmas</Option>
                                <Option value='custom'>Custom</Option>
                            </Select>
                        </FormControl>

                        <BasicDatePicker label='Exchange Date' date={exchangeDate} setValue={handleDatePicker}/>

                        <Button
                            sx={{marginLeft: '5px'}}
                            color={props.item.purchasedBy ? "primary" : "success"}
                            variant='soft'
                            disabled={isDisabled}
                            loading={isDisabled}
                            onClick={handlePurchased}>
                            {props.item.purchasedBy ? "Update" : "Purchase"}
                        </Button>

                        {props.item.purchasedBy != null &&
                            <Button
                                variant='soft'
                                color="danger"
                                disabled={isDisabled}
                                loading={isDisabled}
                                onClick={handleRemovePurchase}>
                                Remove
                            </Button>}
                    </Stack>
                </Box>
            </ModalDialog>
        </Modal>
    </React.Fragment>
}
