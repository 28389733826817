import {Stack} from "@mui/joy";
import {IUser} from "../models/user";
import {UserCard} from "./UserCard";

interface IProps {
    users: IUser[];
}

export const UserList = (props: IProps) => {
    return <Stack spacing={1}>
        {
            props.users.map(u => <UserCard user={u} key={u.id}/>)
        }
    </Stack>
}